import { Button, DropdownInput, Typography } from '@getjelly/jelly-ui'
import { IconSelector } from '@tabler/icons-react'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { ContactLinkModal } from './components/ContactLinkModal'

import {
  AccCategory,
  AccChartOfAccount,
  AccContact,
  DueDateType,
  PublishDocumentType,
  useCreateAccAccountToKitchenToSupplierMutation,
  useFullAccAccountQuery,
  useKitchenToAccAccountQuery,
  useKitchenToSupplierXeroQuery,
  useUpdateAccAccountToKitchenToSupplierMutation,
} from '../../../../api'
import { Loader } from '../../../../components'
import { IntegerInput } from '../../../../components/Inputs/IntegerInput'
import { HelpButton } from '../../../../components/Modals/HelpButton'
import { NewLayout } from '../../../../components/newUi'
import { errorToast, successToast } from '../../../../components/toasts'
import { routes } from '../../../../routes/Paths'

const publishDocumentTypes = [
  PublishDocumentType.Draft,
  PublishDocumentType.AwaitingApproval,
  PublishDocumentType.AwaitingPayment,
]

const dueDateTypes = [
  DueDateType.AfterInvoiceDate,
  DueDateType.AfterEndOfInvoiceMonth,
  DueDateType.AfterEndOfCurrentMonth,
  DueDateType.AfterEndOfFollowingMonth,
]

const publishDocumentTypeDisplay: Record<PublishDocumentType, string> = {
  [PublishDocumentType.Draft]: 'Draft',
  [PublishDocumentType.AwaitingApproval]: 'Awaiting Approval',
  [PublishDocumentType.AwaitingPayment]: 'Awaiting Payment',
}

const dueDateTypeDisplay: Record<DueDateType, string> = {
  [DueDateType.AfterInvoiceDate]: 'After Invoice date',
  [DueDateType.AfterEndOfInvoiceMonth]: 'After end of Invoice month',
  [DueDateType.AfterEndOfCurrentMonth]: 'After end of current month',
  [DueDateType.AfterEndOfFollowingMonth]: 'After end of following month',
}

export function XeroInstalledSupplier() {
  const { id } = useParams()
  const { state } = useLocation()
  const navigate = useNavigate()

  const { accAccountId, kitchenToAccAccountId } = (state ?? {}) as {
    accAccountId: number
    kitchenToAccAccountId: number
  }

  useEffect(() => {
    if (!accAccountId) {
      navigate(
        routes.Settings + routes.Integrations + routes.Xero + routes.Installed,
      )
    }
  }, [accAccountId, navigate])

  const [saving, setSaving] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)

  const { data: kitchenToAccAccountData } = useKitchenToAccAccountQuery({
    variables: {
      id: kitchenToAccAccountId,
    },
  })

  const { data: kitchenToSupplierData } = useKitchenToSupplierXeroQuery({
    skip: !id,
    variables: {
      accAccountId,
      id: id ? parseInt(id) : 0,
    },
  })

  const { data: accAccountData } = useFullAccAccountQuery({
    variables: { id: accAccountId },
  })

  const kitchenToAccAccount = useMemo(() => {
    return kitchenToAccAccountData?.kitchenToAccAccount
  }, [kitchenToAccAccountData])

  const kitchenToSupplier = useMemo(() => {
    return kitchenToSupplierData?.kitchenToSupplier
  }, [kitchenToSupplierData])

  const [daysAfter, setDaysAfter] = useState<number | null>(
    kitchenToSupplier?.accAccountToKitchenToSupplier?.daysAfter || null,
  )

  const [accChartOfAccount, setAccChartOfAccount] = useState<Pick<
    AccChartOfAccount,
    'id' | 'name' | 'code'
  > | null>(
    kitchenToSupplier?.accAccountToKitchenToSupplier?.accChartOfAccount || null,
  )

  const [accCategory, setAccCategory] = useState<Pick<
    AccCategory,
    'id' | 'name'
  > | null>(
    kitchenToSupplier?.accAccountToKitchenToSupplier?.accCategory || null,
  )

  const [accContact, setAccContact] = useState<Pick<
    AccContact,
    'id' | 'name'
  > | null>(
    kitchenToSupplier?.accAccountToKitchenToSupplier?.accContact || null,
  )

  const [dueDateType, setDueDateType] = useState<DueDateType | null>(
    kitchenToSupplier?.accAccountToKitchenToSupplier?.dueDateType || null,
  )

  const [createAccAccountToKitchenToSupplier] =
    useCreateAccAccountToKitchenToSupplierMutation({
      awaitRefetchQueries: true,
      onQueryUpdated: ({ options }) =>
        options.variables ? options.variables.id === parseInt(id) : true,
      refetchQueries: ['kitchenToSupplierXero'],
    })

  const [updateAccAccountToKitchenToSupplier] =
    useUpdateAccAccountToKitchenToSupplierMutation({
      awaitRefetchQueries: true,
      onQueryUpdated: ({ options }) =>
        options.variables ? options.variables.id === parseInt(id) : true,
      refetchQueries: ['kitchenToSupplierXero'],
    })

  useEffect(() => {
    if (!kitchenToSupplier?.accAccountToKitchenToSupplier) {
      setAccChartOfAccount(null)
      setAccCategory(null)
      setDaysAfter(null)
      setDueDateType(null)
      setAccContact(null)
      return
    }

    setAccChartOfAccount(
      kitchenToSupplier.accAccountToKitchenToSupplier.accChartOfAccount ?? null,
    )
    setAccCategory(
      kitchenToSupplier.accAccountToKitchenToSupplier.accCategory ?? null,
    )
    setDaysAfter(
      kitchenToSupplier.accAccountToKitchenToSupplier.daysAfter ?? null,
    )
    setDaysAfter(
      kitchenToSupplier.accAccountToKitchenToSupplier.daysAfter ?? null,
    )
    setDueDateType(
      kitchenToSupplier.accAccountToKitchenToSupplier.dueDateType ?? null,
    )
    setAccContact(
      kitchenToSupplier.accAccountToKitchenToSupplier.accContact ?? null,
    )
  }, [kitchenToSupplier])

  if (!accAccountData?.accAccount || !kitchenToSupplier?.supplier) {
    return (
      <>
        <Loader />
      </>
    )
  }

  return (
    <>
      <ContactLinkModal
        accAccount={accAccountData.accAccount}
        accContact={accContact}
        onChange={setAccContact}
        open={showContactModal}
        supplier={kitchenToSupplier.supplier}
        onClose={() => setShowContactModal(false)}
      />

      <NewLayout
        onBack={() =>
          navigate(
            routes.Settings +
              routes.Integrations +
              routes.Xero +
              routes.Installed,
          )
        }
        subtitle="Integrations"
        title="Xero"
        bottomContent={
          <div className="px-2 py-4">
            <Button
              onClick={async () => {
                if (saving) return
                setSaving(true)

                try {
                  if (!kitchenToSupplier?.accAccountToKitchenToSupplier) {
                    await createAccAccountToKitchenToSupplier({
                      variables: {
                        data: {
                          accAccountId,
                          accCategoryId: accCategory?.id,
                          accChartOfAccountId: accChartOfAccount?.id,
                          accContactId: accContact?.id,
                          daysAfter,
                          dueDateType,
                          ktsId: id ? parseInt(id) : 0,
                        },
                      },
                    })

                    successToast('Saved supplier information.')
                  } else {
                    await updateAccAccountToKitchenToSupplier({
                      variables: {
                        data: {
                          accCategoryId: accCategory?.id,
                          accChartOfAccountId: accChartOfAccount?.id,
                          accContactId: accContact?.id,
                          daysAfter,
                          dueDateType,
                          id: kitchenToSupplier.accAccountToKitchenToSupplier
                            .id,
                        },
                      },
                    })

                    successToast('Saved supplier information.')
                  }
                } catch (e) {
                  errorToast('Unable to save supplier data.')
                } finally {
                  setSaving(false)
                }
              }}
              className="w-full"
              loading={saving}
              label="Save"
            />
          </div>
        }
      />

      <div className="p-4 space-y-6 bg-primary-50">
        <div className="space-y-2">
          <div className="space-y-2">
            <Typography style="subtitle1" className="text-primary-900">
              Individual settings
            </Typography>

            <Typography style="subtitle2" className="text-primary-900">
              This specific supplier configuration will override the general
              setting
            </Typography>
          </div>

          <div>
            <div className="flex items-center space-x-2 border-b border-primary-200 py-4">
              <div className="flex-1 space-y-1">
                <Typography style="subtitle1" className="text-primary-900">
                  Default publish documents as
                </Typography>

                <DropdownInput
                  disabled={true}
                  value={kitchenToAccAccount?.publishDocumentType ?? null}
                  options={publishDocumentTypes}
                  optionToId={(o) => o}
                  optionToLabel={(o) => publishDocumentTypeDisplay[o]}
                  onChange={() => void 0}
                />
              </div>

              <div className="shrink-0">
                <HelpButton
                  title="Default publish documents as"
                  body="Select how items will appear in Xero once published."
                />
              </div>
            </div>

            <div className="flex items-center space-x-2 border-b border-primary-200 py-4">
              <div className="flex-1 space-y-1">
                <Typography style="subtitle1" className="text-primary-900">
                  Map to Xero contact
                </Typography>

                <div
                  className="w-full border-2 border-primary-100 pl-3 pr-2 py-2 bg-white rounded-lg flex justify-between items-center cursor-pointer"
                  onClick={() => setShowContactModal(true)}
                >
                  {accContact ? (
                    <Typography style="subtitle2" className="text-primary-900">
                      {accContact.name}
                    </Typography>
                  ) : (
                    <Typography style="subtitle2" className="text-primary-600">
                      Select...
                    </Typography>
                  )}

                  <IconSelector className="text-primary-900" />
                </div>
              </div>

              <div className="shrink-0">
                <HelpButton
                  title="Map to a Xero contact"
                  body="Map this supplier in Jelly to an existing supplier contact in Xero. If the supplier does not exist in Xero, you can create a new contact."
                />
              </div>
            </div>

            <div className="flex items-center space-x-2 border-b border-primary-200 py-4">
              <div className="flex-1 space-y-1">
                <Typography style="subtitle1" className="text-primary-900">
                  Map to a chart of accounts
                </Typography>

                <DropdownInput
                  value={accChartOfAccount}
                  placeholder="Select..."
                  options={accAccountData?.accAccount?.accChartOfAccounts ?? []}
                  optionToId={(o) => o.id}
                  optionToLabel={(o) => `${o.code} - ${o.name}`}
                  onChange={setAccChartOfAccount}
                />
              </div>

              <div className="shrink-0">
                <HelpButton
                  title="Map to a chart of accounts"
                  body="Select a single chart of accounts in Xero to map to this supplier."
                />
              </div>
            </div>

            <div className="flex items-center space-x-2 border-b border-primary-200 py-4">
              <div className="flex-1 space-y-1">
                <Typography style="subtitle1" className="text-primary-900">
                  Due date
                </Typography>

                <div className="space-y-2">
                  <div className="w-28 flex space-x-2 items-center">
                    <IntegerInput value={daysAfter} onChange={setDaysAfter} />

                    <Typography style="body1" className="text-primary-900">
                      days
                    </Typography>
                  </div>

                  <DropdownInput
                    value={dueDateType}
                    placeholder="Select..."
                    options={dueDateTypes}
                    optionToId={(o) => o}
                    optionToLabel={(o) => dueDateTypeDisplay[o]}
                    onChange={setDueDateType}
                  />
                </div>
              </div>

              <div className="shrink-0">
                <HelpButton
                  title="Due date"
                  body="Set the default due date for invoices from this supplier."
                />
              </div>
            </div>

            <div className="flex items-center space-x-2 border-b border-primary-200 py-4">
              <div className="flex-1 space-y-1">
                <Typography style="subtitle1" className="text-primary-900">
                  Map to a tracking category / option (Optional)
                </Typography>

                <DropdownInput
                  value={accCategory}
                  placeholder="Select..."
                  options={accAccountData?.accAccount?.accCategories ?? []}
                  optionToId={(o) => o.id}
                  optionToLabel={(o) => o.name}
                  onChange={setAccCategory}
                />
              </div>

              <div className="shrink-0">
                <HelpButton
                  title="Map to a tracking category / option (Optional)"
                  body="Select a Tracking Category and Tracking Option for this supplier in Xero."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import { Typography } from '@getjelly/jelly-ui'
import { IconLoader2 } from '@tabler/icons-react'
import { Dispatch, SetStateAction } from 'react'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized'

import { AccContactRow } from './AccContactRow'

import { AccContact } from '../../../../../api'

type Props = {
  search: string
  loading: boolean
  accContacts: Pick<AccContact, 'id' | 'name'>[]
  selectedAccContact: Pick<AccContact, 'id' | 'name'> | null
  setSelectedAccContact: Dispatch<
    SetStateAction<Pick<AccContact, 'id' | 'name'> | null>
  >
}

export function AccContactList({
  accContacts,
  loading,
  selectedAccContact,
  setSelectedAccContact,
  search,
}: Props) {
  const cache = new CellMeasurerCache({
    defaultHeight: 100,
    fixedWidth: true,
  })

  if (!search) {
    return (
      <div className="text-center py-8 px-8 space-y-2">
        <Typography style="h6">Start your search</Typography>

        <Typography style="caption" className="text-primary-600">
          Search for a contact in your Xero organisation to link to this
          supplier.
        </Typography>
      </div>
    )
  }

  if (accContacts.length <= 0 && loading) {
    return (
      <div className="flex justify-center py-12 px-8 space-y-2">
        <IconLoader2 size={32} className="text-primary-900 animate-spin" />
      </div>
    )
  }

  if (accContacts.length <= 0) {
    return (
      <div className="text-center py-8 px-8 space-y-2">
        <Typography style="h6">No contacts found</Typography>

        <Typography style="caption" className="text-primary-600">
          We could not find any contacts for that search.
        </Typography>
      </div>
    )
  }

  return (
    <div className="h-40">
      <AutoSizer>
        {({ height, width }) => {
          return (
            <List
              width={width}
              height={height}
              rowHeight={(params) => cache.rowHeight(params)}
              deferredMeasurementCache={cache}
              rowCount={accContacts.length}
              rowRenderer={({ index, key, style, parent }) => {
                const accContact = accContacts[index]

                return (
                  <CellMeasurer
                    key={key}
                    cache={cache}
                    parent={parent}
                    columnIndex={0}
                    rowIndex={index}
                  >
                    {({ measure }) => (
                      <div style={style} className="border-b border-gray-200">
                        <AccContactRow
                          key={accContact.id}
                          accContact={accContact}
                          active={selectedAccContact?.id === accContact.id}
                          onClick={() => {
                            setSelectedAccContact(accContact)
                            measure()
                          }}
                        />
                      </div>
                    )}
                  </CellMeasurer>
                )
              }}
              overscanRowCount={3}
            />
          )
        }}
      </AutoSizer>
    </div>
  )
}

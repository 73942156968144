import { NumberInput } from '@getjelly/jelly-ui'
import { useEffect, useState } from 'react'

type Props = {
  min?: number
  max?: number
  error?: string
  value: number | null
  onChange: (money: number | null) => void
  placeholder?: string
  loading?: boolean
  disabled?: boolean
}

export function IntegerInput({
  min,
  max,
  error,
  placeholder,
  value,
  onChange,
  loading,
  disabled,
}: Props) {
  const [localValue, setLocalValue] = useState(
    value === null ? '' : value.toFixed(0),
  )

  const [errorMsg, setErrorMsg] = useState(error)
  useEffect(() => setErrorMsg(error), [error])

  useEffect(
    () => setLocalValue(value === null ? '' : value.toFixed(0)),
    [value],
  )

  const handleBlur = () => {
    const parsed = parseFloat(localValue)
    setErrorMsg(undefined)

    if (localValue.trim() === '') {
      onChange(null)
      setLocalValue('')
      return
    }

    if (Number.isNaN(parsed)) {
      onChange(null)
      setLocalValue('')
      setErrorMsg('Invalid value.')
    } else {
      onChange(parsed)
      setLocalValue(parsed.toFixed(0))
    }
  }

  return (
    <NumberInput
      min={min}
      max={max}
      error={errorMsg}
      value={localValue}
      onChange={setLocalValue}
      onBlur={handleBlur}
      placeholder={placeholder}
      loading={loading}
      disabled={disabled}
    />
  )
}

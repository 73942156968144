import { useQuery } from '@apollo/client'
import { Button, DropdownInput, Modal, Typography } from '@getjelly/jelly-ui'
import {
  IconAlertTriangle,
  IconChevronRight,
  IconCircleCheckFilled,
  IconHelpCircle,
} from '@tabler/icons-react'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { accTypeQuery, kitchenToAccAccountsQuery } from './graphql'

import {
  KitchenToSuppliersQuery,
  Query,
  QueryAccTypeArgs,
  QueryKitchenToAccAccountsArgs,
  useKitchenToSuppliersQuery,
} from '../../../../api'
import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { HelpButton } from '../../../../components/Modals/HelpButton'
import { NewLayout } from '../../../../components/newUi'
import { errorToast } from '../../../../components/toasts'
import { routes } from '../../../../routes/Paths'

export function XeroInstalled() {
  const navigate = useNavigate()
  const { selectedKitchen, refetchSelectedKitchen } = useKitchen()

  const [showModal, setShowModal] = useState(false)
  const [disconnecting, setDisconnecting] = useState(false)

  const { data: { accType } = {} } = useQuery<
    { accType: Query['accType'] },
    QueryAccTypeArgs
  >(accTypeQuery, {
    variables: {
      name: 'Xero',
    },
  })

  // const [deleteKitchenToAccAccount] = useMutation<
  //   { deleteOneKitchenToAccAccount: Mutation['deleteOneKitchenToAccAccount'] },
  //   MutationDeleteOneKitchenToAccAccountArgs
  // >(deleteKitchenToAccAccountsMutation)

  const { data: { kitchenToAccAccounts } = {} } = useQuery<
    { kitchenToAccAccounts: Query['kitchenToAccAccounts'] },
    QueryKitchenToAccAccountsArgs
  >(kitchenToAccAccountsQuery, {
    variables: {
      kitchenId: selectedKitchen?.id || 0,
    },
  })

  const kitchenToAccAccount = useMemo(() => {
    if (!accType || !kitchenToAccAccounts) return null

    return kitchenToAccAccounts.find(
      (ktp) => ktp.accAccount.accTypeId === accType.id,
    )
  }, [kitchenToAccAccounts, accType])

  const { data: kitchenToSuppliersData } = useKitchenToSuppliersQuery({
    skip: !kitchenToAccAccount,
    variables: {
      accAccountId: kitchenToAccAccount?.accAccountId || 0,
      kitchenId: selectedKitchen?.id || 0,
    },
  })

  const kitchenToSuppliers = useMemo(() => {
    if (!kitchenToSuppliersData?.kitchenToSuppliers) return []
    return kitchenToSuppliersData.kitchenToSuppliers
  }, [kitchenToSuppliersData])

  const isValidKts = (
    kts: KitchenToSuppliersQuery['kitchenToSuppliers'][number],
  ) => {
    if (!kts.accAccountToKitchenToSupplier) {
      return false
    }

    if (!kts.accAccountToKitchenToSupplier.accChartOfAccountId) {
      return false
    }

    if (!kts.accAccountToKitchenToSupplier.accContactId) {
      return false
    }

    if (!kts.accAccountToKitchenToSupplier.daysAfter) {
      return false
    }

    if (!kts.accAccountToKitchenToSupplier.dueDateType) {
      return false
    }

    return true
  }

  return (
    <>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className="space-y-8">
          <div className="space-y-2 text-center">
            <Typography style="h6" className="text-primary-900">
              Disconnect Xero?
            </Typography>

            <Typography style="caption" className="text-primary-600">
              This will completely disconnect Xero integration and will disable
              publishing, do you want to continue?
            </Typography>
          </div>

          <div className="space-y-2">
            <Button
              style="delete"
              loading={disconnecting}
              onClick={async () => {
                if (disconnecting) {
                  return
                }

                if (!accType) {
                  errorToast('Unable to disconnect integration.')
                  return
                }

                setDisconnecting(true)

                try {
                  for (const ktp of kitchenToAccAccounts || []) {
                    if (ktp.accAccount.accTypeId !== accType.id) {
                      continue
                    }

                    // await deleteKitchenToAccAccount({
                    //   variables: {
                    //     kitchenId: selectedKitchen?.id || 0,
                    //     accAccountId: ktp.accAccountId,
                    //   },
                    // })
                  }

                  await refetchSelectedKitchen()

                  setShowModal(false)
                  navigate(routes.Settings + routes.Integrations)
                } catch (error) {
                  console.error(error)
                  errorToast(
                    'Unable to disconnect Xero. An unknown error has occurred.',
                  )
                } finally {
                  setDisconnecting(false)
                }
              }}
              className="w-full"
              label="Yes, Disconnect Xero"
            />

            <Button
              style="secondary"
              onClick={() => setShowModal(false)}
              className="w-full"
              label="Cancel"
            />
          </div>
        </div>
      </Modal>

      <NewLayout
        onBack={() => navigate(routes.Settings + routes.Integrations)}
        subtitle="Integrations"
        title="Xero"
        bottomContent={
          <div className="px-2 py-4 flex space-x-2 border-t border-primary-100">
            <Button
              style="delete"
              onClick={() => setShowModal(true)}
              label="Disconnect"
              className="w-full"
            />

            <Button
              onClick={() => alert('Refresh data.')}
              label="Refresh Data"
              className="w-full"
            />
          </div>
        }
      />

      <div className="p-4 space-y-6 bg-primary-50">
        <div className="space-y-2">
          <div className="space-y-2">
            <Typography style="subtitle1" className="text-primary-900">
              General settings
            </Typography>

            <Typography style="subtitle2" className="text-primary-900">
              This is the general configuration for Xero
            </Typography>
          </div>

          <div>
            <div className="flex items-center justify-between border-b border-primary-200 py-4">
              <div>
                <Typography style="subtitle1" className="text-primary-900">
                  Organisation
                </Typography>

                <Typography style="subtitle2" className="text-primary-900">
                  {kitchenToAccAccount?.accAccount.name ?? 'unknown'}
                </Typography>
              </div>

              <div className="shrink-0">
                <HelpButton
                  title="Organisation"
                  body="This is the organisation set up in your Xero account and linked to this Jelly location. You cannot connect multiple Xero organisations to a single Jelly location, as this may cause authentication issues. However, you can connect the same Xero organisation to multiple Jelly locations."
                />
              </div>
            </div>

            <div className="flex items-center space-x-2 border-b border-primary-200 py-4">
              <div className="flex-1 space-y-1">
                <Typography style="subtitle1" className="text-primary-900">
                  Default publish documents as
                </Typography>

                <DropdownInput
                  disabled={true}
                  value="Draft"
                  options={['Draft']}
                  optionToId={(o) => o}
                  optionToLabel={(o) => o}
                  onChange={() => void 0}
                />
              </div>

              <div className="shrink-0">
                <HelpButton
                  title="Default publish documents as"
                  body="Select how items will appear in Xero once published."
                />
              </div>
            </div>

            <div className="flex items-center space-x-2 border-b border-primary-200 py-4">
              <div className="flex-1 space-y-1">
                <Typography style="subtitle1" className="text-primary-900">
                  When should documents publish
                </Typography>

                <DropdownInput
                  disabled={true}
                  value="Only manually selected"
                  options={['Only manually selected']}
                  optionToId={(o) => o}
                  optionToLabel={(o) => o}
                  onChange={() => void 0}
                />
              </div>

              <div className="shrink-0">
                <HelpButton
                  title="When should documents publish"
                  body={
                    <>
                      Choose when documents are published to Xero:
                      <ol className="list-decimal list-inside">
                        <li>
                          Manual – Only selected documents are published.
                          (Recommended when starting with the integration.)
                        </li>

                        <li>
                          Automated – All approved documents in Jelly are
                          automatically published to Xero.
                        </li>
                      </ol>
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-6">
          <div className="space-y-2">
            <Typography style="subtitle1" className="text-primary-900">
              Individual settings
            </Typography>

            <Typography style="subtitle2" className="text-primary-900">
              This configuration applies for individual suppliers
            </Typography>
          </div>

          <div>
            {kitchenToSuppliers.map((kts) => (
              <div
                key={kts.id}
                onClick={() => {
                  if (!kitchenToAccAccount) {
                    errorToast('An unknown error occurred')
                    return
                  }

                  navigate(
                    routes.Settings +
                      routes.Integrations +
                      routes.Xero +
                      routes.Installed +
                      `/${kts.id}`,
                    {
                      state: {
                        accAccountId: kitchenToAccAccount.accAccountId,
                        kitchenId: kitchenToAccAccount.kitchenId,
                        kitchenToAccAccountId: kitchenToAccAccount.id,
                      },
                    },
                  )
                }}
                className="flex items-center border-b border-primary-200 py-4 space-x-2 cursor-pointer"
              >
                <div className="flex-1 flex items-center space-x-2">
                  <div className="shrink-0">
                    {isValidKts(kts) ? (
                      <IconCircleCheckFilled className="text-success-400" />
                    ) : (
                      <IconAlertTriangle className="text-error-400" />
                    )}
                  </div>

                  <div className="flex-1">
                    <Typography style="subtitle1" className="text-primary-900">
                      {kts.supplier.name}
                    </Typography>
                  </div>
                </div>

                <div className="shrink-0">
                  <IconChevronRight className="text-primary-600" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

import { Button, Modal, TextInput, Typography } from '@getjelly/jelly-ui'
import { useEffect, useState } from 'react'

import { AccContact, Supplier } from '../../../../../api'
import { errorToast } from '../../../../../components/toasts'

type Props = {
  open: boolean
  supplier: Pick<Supplier, 'name'>
  onClose: (accContact: Pick<AccContact, 'id' | 'name'> | null) => void
}

async function createAccContact(): Promise<Pick<
  AccContact,
  'id' | 'name'
> | null> {
  await new Promise((resolve) => setTimeout(resolve, 2000))

  return null
}

export function CreateXeroContactModal({ open, onClose, supplier }: Props) {
  const [creating, setCreating] = useState(false)
  const [name, setName] = useState(supplier.name || '')

  useEffect(() => setName(supplier.name || ''), [open, supplier])

  return (
    <Modal open={open} onClose={() => onClose(null)}>
      <div className="space-y-8">
        <div className="space-y-6">
          <Typography style="h6" className="text-primary-900">
            Create new Xero contact
          </Typography>

          <div className="space-y-3">
            <div className="space-y-2">
              <Typography style="caption" className="text-primary-900">
                Name
              </Typography>

              <TextInput value={name} onChange={setName} placeholder="Name" />
            </div>
          </div>
        </div>

        <div className="space-y-2">
          <Button
            onClick={async () => {
              if (creating) return

              if (!name) {
                errorToast('Contact name is required.')
                return
              }

              setCreating(true)

              try {
                const contactCreate = await createAccContact()

                onClose(contactCreate)
              } catch (e) {
                errorToast((e as Error).message || 'An unknown error occurred.')
              } finally {
                setCreating(false)
              }
            }}
            label="Create Contact And Link"
            className="w-full"
            loading={creating}
          />

          <Button
            style="secondary"
            onClick={() => onClose(null)}
            label="Cancel"
            className="w-full"
          />
        </div>
      </div>
    </Modal>
  )
}

import { gql } from '@apollo/client'

// export const updateKitchenAccCategoriesMutation = gql`
//   mutation updateKitchenAccCategories(
//     $kitchenId: Int!
//     $accCategories: [Int!]!
//     $accTypeName: String!
//   ) {
//     updateKitchenAccCategories(
//       kitchenId: $kitchenId
//       accCategories: $accCategories
//       accTypeName: $accTypeName
//     ) {
//       id
//     }
//   }
// `

export const createOneXeroAuthoriseUrlMutation = gql`
  mutation createOneXeroAuthoriseUrl($kitchenId: Int!) {
    createOneXeroAuthoriseUrl(kitchenId: $kitchenId) {
      url
    }
  }
`

export const createAccAccountForXeroMutation = gql`
  mutation createOneAccAccountForXero($data: CreateAccAccountXeroInput!) {
    createOneAccAccountForXero(data: $data) {
      id
    }
  }
`

export const kitchenToAccAccountsQuery = gql`
  query kitchenToAccAccounts($kitchenId: Int!) {
    kitchenToAccAccounts(kitchenId: $kitchenId) {
      id
      accAccountId
      accAccount {
        id
        name
        accTypeId
      }
      createdAt
    }
  }
`

// export const kitchenToAccLocationsQuery = gql`
//   query kitchenToAccLocations($kitchenId: Int!, $accAccountId: Int!) {
//     kitchenToAccLocations(kitchenId: $kitchenId, accAccountId: $accAccountId) {
//       id
//       accLocation {
//         id
//         name
//       }
//     }
//   }
// `

export const accTypeQuery = gql`
  query accType($name: String!) {
    accType(name: $name) {
      id
    }
  }
`

// export const accLocationsQuery = gql`
//   query accLocations($accAccountId: Int!) {
//     accLocations(accAccountId: $accAccountId) {
//       id
//       name
//       kitchenId
//     }
//   }
// `

// export const linkAccLocationMutation = gql`
//   mutation linkAccLocation($data: LinkAccLocationInput!) {
//     linkAccLocation(data: $data) {
//       id
//     }
//   }
// `

// export const deleteKitchenToAccAccountsMutation = gql`
//   mutation deleteOneKitchenToAccAccount($kitchenId: Int!, $accAccountId: Int!) {
//     deleteOneKitchenToAccAccount(
//       kitchenId: $kitchenId
//       accAccountId: $accAccountId
//     ) {
//       id
//     }
//   }
// `

import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Anchor, Button, Modal, Typography } from '@getjelly/jelly-ui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Header } from './components/Header'
import {
  kitchenToAccAccountsQuery,
  accTypeQuery,
  createOneXeroAuthoriseUrlMutation,
} from './graphql'

import {
  Mutation,
  MutationCreateOneXeroAuthoriseUrlArgs,
  Query,
  QueryKitchenToAccAccountsArgs,
  QueryAccTypeArgs,
} from '../../../../api'
import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { NewLayout } from '../../../../components/newUi'
import { errorToast } from '../../../../components/toasts'
import { routes } from '../../../../routes/Paths'

export function XeroInstall() {
  const { selectedKitchen, refetchSelectedKitchen } = useKitchen()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const [createAuthoriseUrl] = useMutation<
    { createOneXeroAuthoriseUrl: Mutation['createOneXeroAuthoriseUrl'] },
    MutationCreateOneXeroAuthoriseUrlArgs
  >(createOneXeroAuthoriseUrlMutation)

  const { data: { accType } = {} } = useQuery<
    { accType: Query['accType'] },
    QueryAccTypeArgs
  >(accTypeQuery, {
    variables: {
      name: 'Xero',
    },
  })

  const [getKTAAs] = useLazyQuery<
    { kitchenToAccAccounts: Query['kitchenToAccAccounts'] },
    QueryKitchenToAccAccountsArgs
  >(kitchenToAccAccountsQuery, {
    variables: {
      kitchenId: selectedKitchen?.id || 0,
    },
  })

  return (
    <>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className="space-y-8">
          <div className="space-y-2 text-center">
            <Typography style="h6" className="text-primary-900">
              Connection Issue
            </Typography>

            <Typography style="caption" className="text-primary-600">
              We cannot establish connection. Please try Step 1 to 3 again.
            </Typography>
          </div>

          <Button
            onClick={() => setShowModal(false)}
            label="Ok"
            className="w-full"
          />
        </div>
      </Modal>

      <NewLayout
        onBack={() => navigate(routes.Settings + routes.Integrations)}
        subtitle="Integrations"
        title="Xero"
        bottomContent={
          <div className="px-2 py-4 border-t border-primary-100">
            <Button
              onClick={async () => {
                if (loading || !accType) {
                  return
                }

                setLoading(true)

                const { data } = await getKTAAs()

                for (const ktaa of data?.kitchenToAccAccounts ?? []) {
                  if (ktaa.accAccount.accTypeId !== accType.id) {
                    continue
                  }

                  await refetchSelectedKitchen()

                  navigate(
                    routes.Settings +
                      routes.Integrations +
                      routes.Xero +
                      routes.Installed,
                  )

                  return
                }

                return setShowModal(true)
              }}
              label="I Have Authorised Jelly On Xero"
              className="w-full"
            />
          </div>
        }
      />

      <div className="p-4 space-y-6 bg-primary-50">
        <Header />

        <div className="space-y-4">
          <Typography style="subtitle1" className="text-primary-900">
            Authorise Jelly
          </Typography>

          <Typography style="subtitle2" className="text-primary-900">
            <span className="font-bold">Step 1:</span> click{' '}
            <Anchor
              onClick={async () => {
                if (loading) return
                setLoading(true)

                try {
                  const { data, errors } = await createAuthoriseUrl({
                    variables: { kitchenId: selectedKitchen?.id || 0 },
                  })

                  if (!data?.createOneXeroAuthoriseUrl) {
                    console.error(errors)
                    errorToast(
                      'Unable to generate Xero connect URL. Please try again later.',
                    )
                    return
                  }

                  const { url } = data.createOneXeroAuthoriseUrl
                  window.open(url, '_blank')?.focus()
                } catch (e) {
                  console.error(e)
                  errorToast('Unknown error occurred. Please try again later.')
                } finally {
                  setLoading(false)
                }
              }}
              className="text-primary-900 !font-bold underline"
              style="subtitle2"
            >
              this link
            </Anchor>{' '}
            to be directed to your Xero account.
          </Typography>

          <Typography style="subtitle2" className="text-primary-900">
            <span className="font-bold">Step 2:</span> once logged in, choose an
            organisation to give Jelly access to.
          </Typography>

          <Typography style="subtitle2" className="text-primary-900">
            <span className="font-bold">Step 3:</span> once you see
            `Successfully Authorised` return to this screen.
          </Typography>
        </div>
      </div>
    </>
  )
}

import { Button, Modal, TextInput, Typography } from '@getjelly/jelly-ui'
import { IconSearch } from '@tabler/icons-react'
import { useEffect, useMemo, useState } from 'react'

import { AccContactList } from './AccContactList'
import { CreateXeroContactModal } from './CreateXeroContactModal'

import { AccAccount, AccContact, Supplier } from '../../../../../api'

type Props = {
  accAccount: Pick<AccAccount, 'id' | 'name'> & {
    accContacts: Pick<AccContact, 'id' | 'name'>[]
  }
  accContact: Pick<AccContact, 'id' | 'name'> | null
  onChange: (accContact: Pick<AccContact, 'id' | 'name'> | null) => void
  supplier: Pick<Supplier, 'name'>
  open: boolean
  onClose: () => void
}

export function ContactLinkModal({
  open,
  onClose,
  accAccount,
  accContact,
  supplier,
  onChange,
}: Props) {
  const [selectedAccContact, setSelectedAccContact] = useState<Pick<
    AccContact,
    'id' | 'name'
  > | null>(accContact)

  const [search, setSearch] = useState('')

  const [showCreateAccContactModal, setShowCreateAccContactModal] =
    useState(false)

  useEffect(() => setSelectedAccContact(accContact), [accContact])

  const accContacts = useMemo(() => {
    return [...accAccount.accContacts].filter((ac) =>
      ac.name.toLowerCase().includes(search.toLowerCase()),
    )
  }, [accAccount, search])

  return (
    <>
      <CreateXeroContactModal
        open={showCreateAccContactModal}
        onClose={(created) => {
          setShowCreateAccContactModal(false)

          if (created) {
            setSelectedAccContact(created)
            setSearch('')
            onClose()
          }
        }}
        supplier={supplier}
      />

      <Modal
        open={open && !showCreateAccContactModal}
        onClose={() => {
          setSelectedAccContact(null)
          setSearch('')
          onClose()
        }}
        className="!p-0"
      >
        <div className="py-6">
          <div className="px-4">
            <Typography style="h6" className="text-primary-900">
              Link item
            </Typography>
          </div>

          <div className="space-y-3 py-4 border-b border-gray-200 px-4">
            <div>
              <Typography style="caption" className="text-primary-900">
                Supplier name in Jelly
              </Typography>

              <Typography style="body1" className="text-primary-900">
                {supplier.name}
              </Typography>
            </div>

            <div>
              <div className="space-y-2">
                <Typography style="caption" className="text-primary-900">
                  Match to Xero contact
                </Typography>

                <TextInput
                  value={search}
                  onChange={setSearch}
                  loading={false}
                  placeholder="Search..."
                  icon={IconSearch}
                />
              </div>
            </div>
          </div>

          <div>
            <AccContactList
              search={search}
              loading={false}
              accContacts={accContacts}
              selectedAccContact={selectedAccContact}
              setSelectedAccContact={setSelectedAccContact}
            />
          </div>

          <div className="px-4 pt-4 space-y-2 border-t border-primary-200">
            <Button
              onClick={() => {
                onChange(selectedAccContact)
                setSelectedAccContact(null)
                setSearch('')
                onClose()
              }}
              label="Link Contact"
              className="w-full"
              loading={false}
              disabled={!selectedAccContact}
            />

            <Button
              onClick={() => setShowCreateAccContactModal(true)}
              label="Create As New Contact In Xero"
              style="secondary"
              className="w-full"
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

import { Typography } from '@getjelly/jelly-ui'
import { IconCheck } from '@tabler/icons-react'
import { MouseEventHandler } from 'react'

import { AccContact } from 'api'

type Props = {
  accContact: Pick<AccContact, 'id' | 'name'>
  active: boolean
  onClick: MouseEventHandler<HTMLDivElement>
}

export function AccContactRow({ accContact, active, onClick }: Props) {
  return (
    <div className="p-4 cursor-pointer" onClick={onClick}>
      <div className="flex items-center justify-between">
        <Typography style="subtitle1" className="text-primary-900">
          {accContact.name}
        </Typography>

        {active && (
          <div className="flex items-center shrink-0">
            <IconCheck className="text-primary-900" />
          </div>
        )}
      </div>
    </div>
  )
}

import { Button, Modal, Typography } from '@getjelly/jelly-ui'
import { IconHelpCircle } from '@tabler/icons-react'
import { ReactNode, useState } from 'react'

type Props = {
  title?: string
  body: ReactNode
}

export function HelpButton({ title = 'Help', body }: Props) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div className="space-y-8 text-center">
          <div className="space-y-2">
            <Typography style="h6" className="text-primary-900">
              {title}
            </Typography>
            <Typography style="subtitle2" className="text-primary-600">
              {body}
            </Typography>
          </div>
          <Button
            className="w-full"
            onClick={() => setOpen(false)}
            label="Ok"
          />
        </div>
      </Modal>

      <div className="cursor-pointer" onClick={() => setOpen(true)}>
        <IconHelpCircle className="text-secondary-400" />
      </div>
    </>
  )
}

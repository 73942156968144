import { useMutation } from '@apollo/client'
import { Button, Typography } from '@getjelly/jelly-ui'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Header } from './components/Header'
import { createAccAccountForXeroMutation } from './graphql'

import {
  Mutation,
  MutationCreateOneAccAccountForXeroArgs,
} from '../../../../api'
import { useKitchen } from '../../../../app/contexts/SelectedKitchen'
import { Loader } from '../../../../components'
import { NewLayout } from '../../../../components/newUi'
import { routes } from '../../../../routes/Paths'

export function XeroCallback() {
  const location = useLocation()
  const navigate = useNavigate()
  const { selectedKitchen } = useKitchen()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const params = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)

    return {
      code: searchParams.get('code'),
      state: searchParams.get('state'),
      type: searchParams.get('response_type'),
    }
  }, [location.search])

  const [createAccAccount] = useMutation<
    {
      createOneAccAccountForXero: Mutation['createOneAccAccountForXero']
    },
    MutationCreateOneAccAccountForXeroArgs
  >(createAccAccountForXeroMutation)

  useEffect(() => {
    async function processData() {
      if (!params.code || !params.state || loading) {
        return
      }

      setLoading(true)

      try {
        await createAccAccount({
          variables: {
            data: {
              code: params.code,
              kitchenId: selectedKitchen?.id || 0,
              state: params.state,
            },
          },
        })

        setError('')
      } catch (e) {
        console.error(e)
        setError(
          'Unable to link Xero account. Please try again later or contact Jelly Support.',
        )
      } finally {
        setLoading(false)
      }
    }

    void processData()
  }, [params, selectedKitchen, createAccAccount])

  if (!params.code || !params.state || error) {
    return (
      <>
        <NewLayout
          onBack={() =>
            navigate(routes.Settings + routes.Integrations + routes.Xero)
          }
          subtitle="Integrations"
          title="Xero"
          bottomContent={
            <div className="px-2 py-4 border-t border-primary-100">
              <Button
                onClick={() =>
                  navigate(routes.Settings + routes.Integrations + routes.Xero)
                }
                label="Go Back"
                className="w-full"
              />
            </div>
          }
        />

        <div className="p-4 space-y-6 bg-primary-50">
          <Header />

          <div className="text-center py-8 space-y-2">
            <Typography style="subtitle1" className="text-primary-900">
              Something went wrong.
            </Typography>

            <Typography style="subtitle2" className="text-primary-900">
              {error || 'Please try again later or contact Jelly support.'}
            </Typography>
          </div>
        </div>
      </>
    )
  }

  if (loading) {
    return (
      <>
        <NewLayout hideBack={true} subtitle="Integrations" title="Xero" />

        <div className="p-4 space-y-6 bg-primary-50">
          <Header />

          <Loader />
        </div>
      </>
    )
  }

  return (
    <>
      <NewLayout hideBack={true} subtitle="Integrations" title="Xero" />

      <div className="p-4 space-y-6 bg-primary-50">
        <Header />

        <Typography style="subtitle1" className="text-primary-900">
          Successfully Authorised!
        </Typography>

        <Typography style="subtitle2" className="text-primary-900">
          You can now close this screen and go back to the Jelly app.
        </Typography>
      </div>
    </>
  )
}
